import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import OrganizationImage from './images/organization.png';
import MpqImage from './images/mpq.png';
import SearchImage from './images/searches.png';

export default class Home extends React.Component {
    styles = {
        homeButton: {
        },
        homeButtonIcon: {
        },
        homeButtonText: {
        },
        homeGrid: {
            flexGrow: 1,
            maxWidth: 500,
            padding: 40
        }
    };

    state = {
    }

    componentDidMount = () => {
    }

    logout = (e) => {
        e.preventDefault();
        this.props.signOut();
    }

    launchAction = (action) => {
        this.props.launchAction(action);
    }

    render() {
        return (
            <GridList
                style={this.styles.homeGrid}
                cellHeight={180}>
                <GridListTile
                    style={this.styles.homeButton}
                    onClick={e => this.launchAction('orgs')}>
                    <img src={OrganizationImage} alt='Change organization' />
                    <GridListTileBar
                        title='Organizations'
                        subtitle='Change organization' />
                </GridListTile>

                <GridListTile
                    style={this.styles.homeButton}
                    onClick={e => this.launchAction('mpqs')}>
                    <img src={MpqImage} alt='View Questionnaires' />
                    <GridListTileBar
                        title='MPQs'
                        subtitle='Questionnaires' />
                </GridListTile>

                <GridListTile
                    style={this.styles.homeButton}
                    onClick={e => this.launchAction('searches')}>
                    <img src={SearchImage} alt='Active Searches' />
                    <GridListTileBar
                        title='Searches'
                        subtitle='Active searches' />
                </GridListTile>
            </GridList>
        );
    }
}
