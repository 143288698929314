import React from 'react';
import firebase from 'firebase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BusinessIcon from '@material-ui/icons/Business';

export default class Organizations extends React.Component {
    state = {
        organizations: [],
        dataAvailable: false
    }

    cleanResults = (val) => {
        let results = [];

        if (val) {
            Object.entries(val).forEach(m => {
                const id = m[0];
                let q = m[1];
                results.push({
                    id: id,
                    ...q
                });
            });
            console.log(results);
        }

        return results;
    };

    componentDidMount = () => {
        const rootRef = firebase.database().ref().child('organizations');
        rootRef.once('value', snap => {
            const results = this.cleanResults(snap.val());
            console.log(results);
            this.setState({
                organizations: results,
                dataAvailable: true
            });
        });
    }

    logout = (e) => {
        e.preventDefault();
        this.props.signOut();
    }

    setOrganization = (org) => {
        this.props.setOrganization(org);
    }

    render() {
        const orgs = this.state.organizations.map((item, key) =>
            <ListItem button key={item.code}>
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText
                    primary={item.name}
                    secondary={item.city + ", " + item.state}
                    onClick={() => this.setOrganization(item)}
                />
            </ListItem>
        );

        return this.state.dataAvailable && (
            <List>
                {orgs}
            </List>
        );
    }
}
