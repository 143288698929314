import React from 'react';
import firebase from 'firebase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = {
    fab: {
      position: 'absolute',
      bottom: 20,
      right: 20,
    }
  };
  
export default class MPQs extends React.Component {
    state = {
        mpqs: [],
        dataAvailable: false,
        organization: {}
    }

    cleanResults = (val) => {
        let results = [];

        if (val) {
            Object.entries(val).forEach(m => {
                const id = m[0];
                let q = m[1];
                results.push({
                    id: id,
                    ...q
                });
            });
            console.log(results);
        }

        return results;
    };

    componentDidMount = () => {
        const orgId = this.props.organization.id;
        console.log(orgId);

        const rootRef = firebase.database().ref().child('organizations/' + orgId + '/mpqs');
        rootRef.once('value', snap => {
            const results = this.cleanResults(snap.val());
            console.log(results);
            this.setState({
                mpqs: results,
                dataAvailable: true,
                organization: this.props.organization
            });
        });
    }

    logout = (e) => {
        e.preventDefault();
        this.props.signOut();
    }

    setMpq = (mpq) => {
        this.props.setMpq(mpq);
    }

    render() {
        const mpqs = this.state.mpqs.map((item, key) =>
            <ListItem button key={item.id}>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText
                    primary={item.subjectFirstName + ' ' + item.subjectLastName}
                    secondary={item.subjectCity + ", " + item.subjectState}
                    onClick={() => this.setMpq(item)}
                />
            </ListItem>
        );

        return this.state.dataAvailable && (
            <React.Fragment>
                <List>
                    {mpqs}
                </List>
                <Fab
                    style={useStyles.fab}
                    onClick={() => this.setMpq({ id: '' })}
                    color="primary">
                    <AddIcon />
                </Fab>
            </React.Fragment>
        );
    }
}
