import React, { Component } from 'react';
import './App.css';
import * as firebase from 'firebase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import MPQ from './mpq.js';
import Login from './login.js';
import Organizations from './organizations.js';
import MPQs from './mpqs.js';
import Home from './home.js';

var config = {
  apiKey: "AIzaSyCr1XedVFfbegKMBqx_2qb_VDXWfxJZ-lg",
  authDomain: "sar-live.firebaseapp.com",
  databaseURL: "https://sar-live.firebaseio.com",
  projectId: "sar-live",
  storageBucket: "sar-live.appspot.com",
  messagingSenderId: "90273974785",
  appId: "1:90273974785:web:be7df54ef229ffe8b98e00",
  measurementId: "G-FT1M0R8M5X"
};

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 2,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
  }
};

firebase.initializeApp(config);
firebase.analytics();

class App extends Component {
  state = {
    user: null,
    organization: null,
    mpqId: '',
    action: 'home'
  };

  signOut = () => {
    firebase.auth().signOut();
    this.setState({
      user: null
    });
  }

  setOrganization = (org) => {
    this.setState({
      organization: org,
      action: 'home'
    });
  }

  setMpq = (mpq) => {
    this.setState({
      mpqId: mpq.id,
      action: 'mpq'
    });
  }

  launchAction(action) {
    if (this.state.organization == null && action !== 'orgs')
      return;
      
    this.setState({
      action: action
    });
  }

  mpqSaved() {
    this.setState({
      action: 'mpqs'
    });
  }

  render() {
    if (this.state.user == null) {  // Login screen if not logged in already
      return (
        <div className="App">
          <AppBar position="sticky">
            <Toolbar>
              <IconButton edge="start" color="inherit">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" style={styles.title}>
                SAR Live
                    </Typography>
            </Toolbar>
          </AppBar>
          <br />
          <Login
            onLogin={u => this.setState({
              user: u
            })}
          />
        </div>
      );
    } else { // Here we determine the screen based on state.action
      var child = {};
      switch (this.state.action) {
        case 'home':
          child = <Home
            user={this.state.user}
            signOut={() => this.signOut()}
            launchAction={(action) => this.launchAction(action)}
            ogranization={this.state.organization}
          />;
          break;
        case 'orgs':
          child = <Organizations
            user={this.state.user}
            signOut={() => this.signOut()}
            setOrganization={(org) => this.setOrganization(org)}
          />;
          break;
        case 'mpqs':
          child = <MPQs
            user={this.state.user}
            signOut={() => this.signOut()}
            setMpq={(mpq) => this.setMpq(mpq)}
            organization={this.state.organization}
          />;
          break;
        case 'mpq':
          child =
            <div>
              <MPQ
                user={this.state.user}
                signOut={() => this.signOut()}
                organization={this.state.organization}
                mpqId={this.state.mpqId}
                mpqSaved={() => this.mpqSaved()}
              />
            </div>;
          break;
        default:
          child = {};
          break;
      }

      const title = "SAR Live" + (this.state.organization != null ? ": " + this.state.organization.name : "");
      return (
        <div className="App">
          <AppBar position="sticky">
            <Toolbar>
              <IconButton edge="start" color="inherit">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" style={styles.title}>
                {title}
              </Typography>
              <Button
                color="inherit"
                style={styles.menuButton}
                onClick={this.signOut}
              >
                Logout
                  </Button>
            </Toolbar>
          </AppBar><br />

          {child}
        </div>
      );
    }
  }
}

export default App;
