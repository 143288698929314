import React from 'react';
import firebase from 'firebase';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export default class MPQ extends React.Component {
    mpq = [];

    styles = {
        root: {
            flexGrow: '1'
        },
        form: {
            padding: '10px'
        }
    };

    state = {
        // Header information
        formCompletedBy: '',
        policeCase: '',
        datePrepared: '',
        timePrepared: '',
        step: 1,

        // Informant information
        informantFirstName: '',
        informantLastName: '',
        informantRelationship: '',
        informantPhoneNumber: '',
        informantStreetAddress: '',
        informantCity: '',
        informantState: '',
        informantZip: '',
        informantLocation: '',
        informantOpinion: '',

        // Subject information
        subjectFirstName: '',
        subjectMiddleName: '',
        subjectLastName: '',
        subjectAnswersTo: '',
        subjectStreetAddress: '',
        subjectCity: '',
        subjectState: '',
        subjectZip: '',
        subjectHomePhoneNumber: '',
        subjectVehicleMake: '',
        subjectVehicleModel: '',
        subjectVehicleColor: '',
        subjectVehicleLicense: '',

        employerName: '',
        employerStreetAddress: '',
        employerCity: '',
        employerState: '',
        employerZip: '',
        employerPhoneNumber: '',
        employerSupervisor: '',

        birthDate: '',
        age: '',
        sex: '',
        height: '',
        weight: '',
        hairColor: '',
        eyeColor: '',
        hairstyle: '',
        complexion: '',
        firstLanguage: '',
        distinguishingMarks: '',
        medicalDisabilities: '',
        medication: '',
        illnesses: '',
        fitnessLevel: '',
        smoker: '',
        brand: '',
        allergies: '',
        fears: '',
        mentalAttitude: '',
        previousOccupations: '',
        criminalHistory: '',
        hobbies: '',

        // Clothing / equipment
        shoeType: '',
        shoeColor: '',
        shoeSize: '',
        shoeSoleDescription: '',
        socks: '',
        pants: '',
        top: '',
        sweater: '',
        jacket: '',
        raingear: '',
        hat: '',
        gloves: '',
        pack: '',
        food: '',

        // Point last seen
        dateLastSeen: '',
        timeLastSeen: '',
        plsDescription: '',
        plsLatitude: '',
        plsLongitude: '',
        plsUSNG: '',
        vehicleLocation: '',
        intendedRoute: '',
        weather: '',
        lastSeenComments: '',

        // Subject next of kin
        kinFirstName: '',
        kinLastName: '',
        kinRelationship: '',
        kinStreetAddress: '',
        kinCity: '',
        kinState: '',
        kinZip: '',
        kinLocation: '',
        kinOpinion: ''
    }

    componentDidMount = () => {
        if (this.props.mpqId != null) {
            const mpqRef = firebase.database().ref('organizations/' + this.props.organization.id + '/mpqs/' + this.props.mpqId);
            mpqRef.once('value').then((snap) => {
                this.mpq = snap.val();
                this.setState(this.mpq);
            });
        }

        this.setState({
            step: 1
        });
    }

    change = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    onCancel = (e) => {
        e.preventDefault();

        this.props.mpqSaved();
    }

    onSubmit = (e) => {
        e.preventDefault();

        // Save to database
        if (this.props.mpqId != null && this.props.mpqId !== '') {
            const mpqRef = firebase.database().ref('organizations/' + this.props.organization.id + '/mpqs/' + this.props.mpqId);
            mpqRef.set(this.state);
        } else {
            const rootRef = firebase.database().ref('organizations/' + this.props.organization.id + '/mpqs');
            const newMpq = rootRef.push();

            newMpq.set(this.state);
        }

        this.props.mpqSaved();
    }

    prevStep(e) {
        this.setState({
            step: this.state.step - 1
        });
    }

    nextStep(e) {
        this.setState({
            step: this.state.step + 1
        });
    }

    logout = (e) => {
        e.preventDefault();
        this.props.signOut();
    }

    render() {
        const mpq = this.state;
        let questions = {};
        let pageTitle = '';
        switch (this.state.step) {
            case 1:
                pageTitle = 'Subject Information';
                questions = (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectFirstName"
                                    variant="outlined"
                                    label="First Name"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectFirstName}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectMiddleName"
                                    variant="outlined"
                                    label="Middle Name"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectMiddleName}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectLastName"
                                    variant="outlined"
                                    label="Last Name"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectLastName}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectAnswersTo"
                                    variant="outlined"
                                    label="Answers To (e.g., 'Buddy')"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectAnswersTo}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectStreetAddress"
                                    variant="outlined"
                                    label="Street Address"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectStreetAddress}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectCity"
                                    variant="outlined"
                                    label="City"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectCity}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectState"
                                    variant="outlined"
                                    label="State"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectState}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectZip"
                                    variant="outlined"
                                    label="Zip Code"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectZip}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="birthDate"
                                    variant="outlined"
                                    label="Date of Birth"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.birthDate}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectHomePhoneNumber"
                                    variant="outlined"
                                    label="Home Phone No"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectHomePhoneNumber}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={2} xs={4}>
                                <TextField
                                    id="sex"
                                    variant="outlined"
                                    label="Sex"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.sex}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={2} xs={4}>
                                <TextField
                                    id="height"
                                    variant="outlined"
                                    label="Height"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.height}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={2} xs={4}>
                                <TextField
                                    id="weight"
                                    variant="outlined"
                                    label="Weight"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.weight}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="eyeColor"
                                    variant="outlined"
                                    label="Eye Color"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.eyeColor}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="hairColor"
                                    variant="outlined"
                                    label="Hair Color"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.hairColor}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="hairstyle"
                                    variant="outlined"
                                    label="Hair Style"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.hairstyle}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="complexion"
                                    variant="outlined"
                                    label="Complexion"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.complexion}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
                break;
            case 2:
                pageTitle = 'Vehicle Information';
                questions = (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectVehicleMake"
                                    variant="outlined"
                                    label="Vehicle Make"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectVehicleMake}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectVehicleModel"
                                    variant="outlined"
                                    label="Vehicle Model"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectVehicleModel}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectVehicleColor"
                                    variant="outlined"
                                    label="Vehicle Color"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectVehicleColor}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="subjectVehicleLicense"
                                    variant="outlined"
                                    label="License Plate No"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.subjectVehicleLicense}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
                break;
            case 3:
                pageTitle = 'Employment Information';
                questions = (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="employerName"
                                    variant="outlined"
                                    label="Employer"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.employerName}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="employerStreetAddress"
                                    variant="outlined"
                                    label="Street Address"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.employerStreetAddress}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="employerCity"
                                    variant="outlined"
                                    label="City"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.employerCity}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="employerState"
                                    variant="outlined"
                                    label="State"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.employerState}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="employerZip"
                                    variant="outlined"
                                    label="Zip Code"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.employerZip}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="employerPhoneNumber"
                                    variant="outlined"
                                    label="Employer Phone"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.employerPhoneNumber}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="employerSupervisor"
                                    variant="outlined"
                                    label="Supervisor's Name"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.employerSupervisor}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
                break;
            case 4:
                pageTitle = 'Health and Wellness';
                questions = (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="distinguishingMarks"
                                    variant="outlined"
                                    label="Distinguishing Marks"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.distinguishingMarks}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="medicalDisabilities"
                                    variant="outlined"
                                    label="Medical Disabilities"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.medicalDisabilities}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="medication"
                                    variant="outlined"
                                    label="Medication Requirements/Qty on Hand/Duration of Supplies"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.medication}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="illnesses"
                                    variant="outlined"
                                    label="Recent/Current Illness(es)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.illnesses}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    id="fitnessLevel"
                                    variant="outlined"
                                    label="Fitness Level"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.fitnessLevel}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="smoker"
                                    variant="outlined"
                                    label="Smoker (Y/N)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.smoker}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="brand"
                                    variant="outlined"
                                    label="Cigarette Brand"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.brand}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="allergies"
                                    variant="outlined"
                                    label="Allergies"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.allergies}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="fears"
                                    variant="outlined"
                                    label="Fears/Phobias"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.fears}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="mentalAttitude"
                                    variant="outlined"
                                    label="Mental Attitude"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.mentalAttitude}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="previousOccupations"
                                    variant="outlined"
                                    label="Previous Pertinant Occupations"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.previousOccupations}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="criminalHistory"
                                    variant="outlined"
                                    label="Criminal History"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.criminalHistory}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="hobbies"
                                    variant="outlined"
                                    label="Hobbies/Interests"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.hobbies}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
                break;
            case 5:
                pageTitle = 'Clothing / Equipment';
                questions = (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    id="shoeType"
                                    variant="outlined"
                                    label="Shoe Type"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.shoeType}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    id="shoeColor"
                                    variant="outlined"
                                    label="Shoe Color"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.shoeColor}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    id="shoeSize"
                                    variant="outlined"
                                    label="Shoe Size"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.shoeSize}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="shoeSoleDescription"
                                    variant="outlined"
                                    label="Shoe / Sole Description (if available)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.shoeSoleDescription}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="socks"
                                    variant="outlined"
                                    label="Socks"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.socks}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="pants"
                                    variant="outlined"
                                    label="Pants / Shorts (type and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.pants}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="top"
                                    variant="outlined"
                                    label="Top (type and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.top}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="sweater"
                                    variant="outlined"
                                    label="Sweater (type and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.sweater}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="jacket"
                                    variant="outlined"
                                    label="Jacket (type and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.jacket}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="raingear"
                                    variant="outlined"
                                    label="Rain Gear (type and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.raingear}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="hat"
                                    variant="outlined"
                                    label="Hat (type and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.hat}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="gloves"
                                    variant="outlined"
                                    label="Gloves (type and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.gloves}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    id="pack"
                                    variant="outlined"
                                    label="Pack (make and color)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.pack}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="food"
                                    variant="outlined"
                                    label="Food and Drink (type/quantity)"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.food}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
                break;
            case 6:
                pageTitle = 'Informant Information';
                questions = (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantFirstName"
                                    variant="outlined"
                                    label="First Name"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantFirstName}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantLastName"
                                    variant="outlined"
                                    label="Last Name"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantLastName}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantRelationship"
                                    variant="outlined"
                                    label="Relationship to Subject"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantRelationship}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantPhoneNumber"
                                    variant="outlined"
                                    label="Phone No"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantPhoneNumber}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantStreetAddress"
                                    variant="outlined"
                                    label="Street Address"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantStreetAddress}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantCity"
                                    variant="outlined"
                                    label="City"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantCity}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantState"
                                    variant="outlined"
                                    label="State"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantState}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    id="informantZip"
                                    variant="outlined"
                                    label="Zip Code"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantZip}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    id="informantLocation"
                                    variant="outlined"
                                    label="Location"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantLocation}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="informantOpinion"
                                    variant="outlined"
                                    label="Opinion on What Happened"
                                    margin="dense"
                                    fullWidth={true}
                                    value={mpq.informantOpinion}
                                    onChange={e => this.change(e)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
                break;
            case 7:
                pageTitle = 'Point Last Seen';
                questions = (
                    <div>
                    </div>
                );
                break;
            case 8:
                pageTitle = 'Subject Next of Kin';
                questions = (
                    <div>
                    </div>
                );
                break;
            default:
                pageTitle = 'Unknown Information';
                questions = (
                    <div style={this.styles.root}>
                        <form style={this.styles.form}>
                        </form>
                    </div>
                );
        }

        return (
            <div style={this.styles.root}>
                <form style={this.styles.form}>
                    <h3>{pageTitle}</h3>
                    {questions}
                    {this.state.step > 1 && (<Button
                        onClick={e => this.prevStep(e)}
                        color="primary"
                        variant="contained">
                        Previous
                    </Button>)}
                    {this.state.step < 8 && (<Button
                        onClick={e => this.nextStep(e)}
                        color="primary"
                        variant="contained">
                        Next
                    </Button>)}
                    <Button
                        onClick={e => this.onCancel(e)}
                        variant="contained">
                        Cancel
                    </Button>
                    <Button
                        onClick={e => this.onSubmit(e)}
                        color="primary"
                        variant="contained">
                        Save
                    </Button>
                </form>
            </div>
        );
    }
}
