import React from 'react';
import firebase from 'firebase';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export default class Login extends React.Component {
    state = {
        email: '',
        password: ''
    };

    styles = {
        spaced: {
            margin: 20
        }
    };

    componentDidMount() {
        firebase.auth().onAuthStateChanged(firebaseUser => {
            if (firebaseUser) {
                console.log(firebaseUser);
                this.props.onLogin(firebaseUser);
            } else {
                console.log('Not Logged In');
            }
        });
    }

    change = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login = (e) => {
        e.preventDefault();
        const email = this.state.email;
        const pass = this.state.password;
        const auth = firebase.auth();
        const promise = auth.signInWithEmailAndPassword(email, pass);
        promise.catch(e => console.log(e.message));
    };

    render() {
        return (
            <div>
                <form>
                    <TextField 
                        label="Email"
                        name='email' 
                        variant="outlined"
                        style={this.styles.spaced}
                        defaultValue={this.state.email}
                        onChange={e => this.change(e)}
                    /><br/>
                    <TextField 
                        label="Password"
                        type='password' 
                        name='password'
                        variant="outlined"
                        style={this.styles.spaced}
                        defaultValue={this.state.password} 
                        onChange={e => this.change(e)}
                    /><br/>
                <Button
                    onClick={e => this.login(e)}
                    style={this.styles.spaced}
                    color="primary"
                    variant="contained"
                >
                    Login
                </Button>
                </form>
            </div>
        );
    }
}
